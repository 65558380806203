.facebook-search-navbar-wrapper {
    width: 15.3vw;
    height: 100vh;
    background: #F9FDFF;
    border-right: 2px solid #D1D2D3;

    &__current-position {
        display: flex;
        align-items: center;

        height: 5.5vh;
        padding-left: 4%;
        margin: 4.2vh 0;
        // border: 1px solid red

        &__checkbox {
            width: 2.5vmin;
            height: 2.5vmin;

            border: 2px solid rgba(0, 0, 0, 0.65);
            box-sizing: border-box;
            border-radius: 2px;

            cursor: pointer; }

        .checked {
            border: 0;
            background: #0074AB no-repeat;
            background-image: url(../../../src/Icons/background/Mark.svg);
            background-position: 0.52vmin 0.7vmin;
            background-size: 1.5vmin; }


        &__title {
            // border: 1px solid red
            margin-left: 0.7vw;
            font-family: Geometria;
            font-style: normal;
            font-weight: 900;
            font-size: 0.9vw;
            line-height: 20px;

            letter-spacing: 0.01em;
            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

            color: rgba(0, 0, 0, 0.9); } }

    &__tabs-wrapper {
        flex-direction: column;
        height: 5.5vh;
        transition: height 0.5s ease-in-out;
        overflow: hidden;
        cursor: pointer;
        // border: 1px solid red

        box-sizing: border-box;

        &__title-field {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 5.5vh;
            width: 100%;
            // border: 1px solid black

            &__title {
                width: 11vw;
                margin-left: 1.55vw;
                font-family: Geometria;
                font-style: normal;
                font-weight: normal;
                font-size: 0.78vw;

                display: flex;
                align-items: center;

                letter-spacing: 0.08em;
                text-transform: uppercase;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                color: #181C20; } }

        &__arrow {
            // border: 1px solid black
            display: flex;
            align-items: center;
            width: 0.5vw;
            height: 100%;
            margin-right: 0.85vw;

            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none; }

        .up {
            transform: rotate(180deg); }

        &__input-field {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12.35vw;
            height: 5.5vh;
            margin: 0 1.42vw;
            margin-top: 2.7vh;
            border: 3px solid #0074AB;
            border-radius: 5px;


            input {
                outline: 0;
                display: flex;
                align-items: center;

                padding-left: 1.1vw;

                width: 12.27vw;
                height: 5.33vh;

                border: 1px solid rgba(0, 0, 0, 0.3);
                box-sizing: border-box;
                border-radius: 2px;

                font-family: Geometria;
                font-style: normal;
                font-weight: normal;
                font-size: 0.91vw;
                line-height: 20px;

                letter-spacing: 0.01em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                color: rgba(0, 0, 0, 0.9); } }

        &__list {
            // border: 1px solid red
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            position: absolute;
            top: 34vh;

            max-height: 48vh;
            height: fit-content;
            width: 12.6vw;

            padding-top: 2vh;

            background: #FFFFFF;

            box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.1);
            border-radius: 3px;

            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            transition: all 0.5s ease-in-out;
            z-index: 100;

            &::-webkit-scrollbar {
                width: 0px; }

            &__loader {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 30vh; }

            &__strings {
                display: flex;
                align-items: center;
                width: 100%;
                height: fit-content;
                padding: 1vw;
                transition: all 0.2s ease-in-out;
                box-sizing: border-box;

                &:hover {
                    background: rgba(138, 153, 168, 0.15); }

                &__checkbox {
                    width: 2.5vmin;
                    height: 2.5vmin;

                    border: 2px solid rgba(0, 0, 0, 0.65);
                    box-sizing: border-box;
                    border-radius: 2px;

                    cursor: pointer; }

                .checked {
                    border: 0;
                    background: #0074AB no-repeat;
                    background-image: url(../../../src/Icons/background/Mark.svg);
                    background-position: 0.52vmin 0.7vmin;
                    background-size: 1.5vmin; }

                &__title {
                    width: 8vw;
                    margin-left: 0.7vw;

                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.91vw;
                    line-height: 20px;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: rgba(0, 0, 0, 0.9); } } }
        .job {
            top: 28.5vh; }

        &__chose-titles {
            display: block;
            margin: 1.3vw;
            width: 12.5vw;
            height: 30vh;

            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0px; }

            &__line {
                margin-bottom: 2vh;
                display: flex;
                align-items: center;
                width: 100%;
                height: fit-content;

                &__checkbox {
                    margin-left: 1.2vw;
                    width: 2.5vmin;
                    height: 2.5vmin;

                    border: 0;
                    background: #0074AB no-repeat;
                    background-image: url(../../../src/Icons/background/Mark.svg);
                    background-position: 0.52vmin 0.7vmin;
                    background-size: 1.5vmin;

                    border-radius: 2px;

                    cursor: pointer; }

                &__tilte {
                    height: fit-content;
                    margin-left: 0.9vw;
                    width: 8vw; } } } }

    .opening {
        display: flex;
        align-items: flex-start;
        height: 50vh;
 } }        // border: 1px solid black

