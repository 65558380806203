.verify-receipts-wrapper {
    position: relative;
    padding: 0 1%;

    width: 100%;
    height: 100%;

    box-sizing: border-box;
    // border: 1px solid red


    &__table-head {
        display: flex;

        &__title {
            height: 5.4vh;

            // border: 1px solid red
            display: flex;
            align-items: center;

            font-family: Geometria;
            font-style: normal;
            font-weight: normal;
            font-size: 0.79vw;
            line-height: 16px;

            letter-spacing: 0.08em;
            text-transform: uppercase;
            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

            color: #595959;

            box-sizing: border-box;

            &:nth-last-child(6) {
                $widthToColumn: 15.6vw !global;
                width: $widthToColumn;

                // border: 1px solid blue
                padding-left: 2.7%; }


            &:nth-last-child(5) {
                $widthFromColumn: 15.6vw !global;
                width: $widthFromColumn;
                // border: 1px solid blue

                padding-left: 3.8%; }

            &:nth-last-child(4) {
                $widthPeriodColumn: 12.4vw !global;
                width: $widthPeriodColumn;
                // border: 1px solid blue

                padding-left: 1%; }

            &:nth-last-child(3) {
                $widthAmountColumn: 9.7vw !global;
                width: $widthAmountColumn;
                // border: 1px solid blue

                padding-left: 1.35%; }

            &:nth-last-child(2) {
                $widthReceiptColumn: 6.5vw !global;
                width: $widthReceiptColumn;
 }                // border: 1px solid blue

            &:nth-last-child(1) {
                $widthActionColumn: 6.5vw !global;
                width: $widthActionColumn;
                // border: 1px solid blue

                padding-left: 1.3%; } } }

    &__table-wrapper {
        position: relative;
        display: block;
        // border: 1px solid red
        min-height: 60vh;
        height: fit-content;
        padding-bottom: 10%;


        &__strings {
            // border: 1px solid red

            display: flex;
            align-items: center;

            width: 100%;
            height: 5.5vh;

            padding: 0.9%;
            box-sizing: border-box;

            border-top:  1px solid #E5E5E5;

            &__button {
                width: 3.9vmin; }

            &__to {
                display: flex;
                // border: 1px solid blue
                width: $widthToColumn;

                padding-left: 3%;
                box-sizing: border-box; }


            &__from {
                display: flex;
                // border: 1px solid blue
                width: $widthFromColumn; }

            &__period {
                display: flex;
                // border: 1px solid blue
                width: $widthPeriodColumn; }

            &__amount {
                display: flex;
                // border: 1px solid blue
                width: $widthAmountColumn; }

            &__receipt {
                display: flex;
                width: $widthReceiptColumn; }

            &__actions {
                display: flex;
                align-items: center;

                width: $widthActionColumn;
                height: 4.5vh;

                background: #0074AB;
                border-radius: 3px;

                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    background: #1753ac; }


                &__title {
                    // border: 1px solid red
                    width: $widthActionColumn;
                    height: 4.4vh;

                    font-family: Geometria-Light;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.91vw;
                    line-height: 20px;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    text-align: center;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #FFFFFF; } }

            &__title {
                width: 100%;

                font-family: Geometria;
                font-style: normal;
                font-weight: normal;
                font-size: 0.91vw;
                line-height: 20px;

                display: flex;
                align-items: center;
                letter-spacing: 0.01em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                padding-left: 3.6%;

                color: #000000; } } } }

