.loader-window-wrapper {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    background: rgb(255, 255, 255);

    z-index: 100; }
