.allPayments-wrapper {
    position: relative;
    // border: 1px solid red
    height: 100%;

    &__filters {
        // border: 1px solid red
        padding-left: 8.4%;

        &__title {
            // border: 1px solid red
            width: 5vw;
            height: 3vh;

            font-family: Geometria;
            font-style: normal;
            font-weight: 500;
            font-size: 1.3vw;
            line-height: 20px;

            letter-spacing: 0.01em;
            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

            color: #0074AB;

            margin-bottom: 2.3%;
            box-sizing: border-box; }

        &__input-wrapper {
            display: flex;
            align-items: center;
            gap: 7.5%;
            width: fit-content;

            &__input {
                display: flex;
                align-items: center;
                justify-content: center;

                width: 14.3vw;
                height: 5.3vh;

                border: 3px solid #0074AB;
                border-radius: 5px;


                input {
                    outline: 0;

                    display: flex;
                    align-items: center;

                    margin-bottom: 0.1px;
                    padding-left: 9%;

                    width: 12.4vw;
                    height: 5.3vh;

                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 2px;

                    box-sizing: border-box;

                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.91vw;
                    line-height: 20px;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: rgba(0, 0, 0, 0.9); } } } }

    &__table-wrapper {
        // border: 1px solid black

        padding: 0 1.5%;
        height: 90vh;
        padding-top: 9%;

        box-sizing: border-box;

        &__header-wrapper {
            display: flex;

            &__header {
                // border: 1px solid green
                height: 5.5vh;
                font-family: Geometria;
                font-style: normal;
                font-weight: normal;
                font-size: 0.8vw;
                line-height: 16px;

                display: flex;
                align-items: center;

                letter-spacing: 0.08em;
                text-transform: uppercase;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                color: #595959;
                box-sizing: border-box;


                &:nth-last-child(5) {
                    $NameColumnWidth: 15.6vw !global;
                    width: $NameColumnWidth;
                    padding-left: 3.7%; }

                &:nth-last-child(4) {
                    $PeriodColumnWidth: 14.3vw !global;
                    width: $PeriodColumnWidth;
                    padding-left: 2.7%; }

                &:nth-last-child(3) {
                    $StatusColumnWidth: 17.5vw !global;
                    width: $StatusColumnWidth;
                    padding-left: 1.7%; }

                &:nth-last-child(2) {
                    $AmountColumnWidth: 8.5vw !global;
                    width: $AmountColumnWidth; }

                &:nth-last-child(1) {
                    $ReceiptColumnWidth: 9vw !global;
                    width: $ReceiptColumnWidth;
                    padding-left: 1.7%; } } }


        &__data-wrapper {
            position: relative;
            display: block;
            // border: 1px solid black
            min-height: 30vh;
            padding-bottom: 10%;

            &__string {
                display: flex;
                align-items: center;
                border-top: 1px solid #E5E5E5;
                height: 5.6vh;
                // border: 1px solid red

                &__name {
                    display: flex;
                    align-items: center;
                    gap: 3.4%;
                    width: $NameColumnWidth;
                    // border: 1px solid black

                    img {
                        border-radius: 50%;
                        width: 4vmin; } }

                &__period {
                    width: $PeriodColumnWidth;
 }                    // border: 1px solid black

                &__status {
                    display: flex;
                    align-items: center;
                    width: $StatusColumnWidth;
                    // border: 1px solid black
                    height: 3.5vh; }

                &__amount {
                    width: $AmountColumnWidth;
 }                    // border: 1px solid black

                &__receipt {
                    margin: 0 auto;
                    width: $ReceiptColumnWidth; }

                &__title {
                    display: flex;
                    align-items: center;
                    padding: 0 0.15vw;
                    width: fit-content;
                    height: 100%;

                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.91vw;
                    line-height: 20px;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #000000; } } } } }
