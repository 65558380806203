.facebook-results-wrapper {
    position: relative;
    width: 70vw;
    height: 100%;
    box-sizing: border-box;


    &__header-title {
        // border: 1px solid green
        width: 100%;
        height: 7vh;

        margin-left: 2.1vw;
        margin-top: 5.5vh;

        font-family: Geometria-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 2.6vw;
        letter-spacing: -0.025em;
        font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

        color: #000000; }

    &__table-wrapper {
        position: relative;
        height: 69vh;
        margin-top: 3vh;
        padding: 4vmin;
        box-sizing: border-box {
 }            // border: 1px solid green

        &__table-header {
            display: flex;
            width: 100%;
            height: 5.5vh;
            // border-bottom: 1px solid #E5E5E5

            &__title {
                display: flex;
                align-items: center;

                height: 5.5vh;

                font-family: Geometria;
                font-style: normal;
                font-weight: normal;
                font-size: 0.8vw;
                line-height: 16px;

                display: flex;
                align-items: center;

                letter-spacing: 0.08em;
                text-transform: uppercase;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;


                &:nth-last-child(4) {
                    $NameColumnWidth: 15.6vw !global;
                    width: $NameColumnWidth;
                    padding-left: 2.6vw;
                    box-sizing: border-box;
 }                    // border: 1px solid red

                &:nth-last-child(3) {
                    padding-left: 2vw;
                    box-sizing: border-box;
                    $JobsColumnWidth: 16.2vw !global;
                    width: $JobsColumnWidth; }

                &:nth-last-child(2) {
                    $CompanyColumnWidth: 15.6vw !global;
                    width: $CompanyColumnWidth; }

                &:nth-last-child(1) {
                    // border: 1px solid red
                    $DateColumnWidth: 16.2vw !global;
                    width: $DateColumnWidth; } } }

        &__results-wrapper {
            position: relative;
            height: 58vh;

            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;

            &::-webkit-scrollbar {
                width: 0px; }

            &__strings {
                display: flex;
                border-top: 1px solid #E5E5E5;
                padding-top: 1.2vh;

                &__nickname {
                    display: flex;
                    flex-direction: column;
                    // border: 1px solid red
                    align-items: center;

                    width: $NameColumnWidth;

                    font-family: Geometria;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 1.3vw;
                    line-height: 20px;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on;

                    color: #000000;
                    text-align: center;

                    &__avatar {
                        height: 5.1vmin;
                        width: 5.1vmin;
                        border-radius: 50%;
                        margin-bottom: 6px; } }

                &__inforamtion {
                    width: 49vw;

                    &__title-wrapper {
                        display: flex;
                        width: 100%;
                        box-sizing: border-box;
                        border-bottom: 1px solid #E5E5E5;
                        padding: 4.1vmin;
                        box-sizing: border-box;

                        &:last-child {
                            border-bottom: 0; }

                        &__title {
                            padding-right: 1.5vw;
                            box-sizing: border-box;
                            text-overflow: ellipsis;
                            font-family: Geometria;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.05vw;

                            display: flex;
                            align-items: center;
                            letter-spacing: 0.01em;
                            font-feature-settings: 'tnum' on, 'lnum' on;

                            color: #000000; }

                        .jobs {
                            width: $JobsColumnWidth; }

                        .companies {
                            width: $CompanyColumnWidth; }

                        .dates {
                            width: $DateColumnWidth; } } } } } }

    &__pagination {
        margin-top: 2.9vh;
        height: 9.5vh;
        padding-left: 2.2vw; } }
