.navbar-wrapper {
    position: relative;
    border-right: 2px solid #D1D2D3;
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &__head {
        // border: 1px solid black
        display: flex;
        justify-content: center;

        width: 88%;
        border-bottom: 2px solid #D1D2D3;

        padding-top: 18%;
        padding-bottom: 6.8%;

        box-sizing: border-box;

        font-feature-settings: 'tnum' on, 'lnum' on;
        font-family: Geometria-light;
        font-weight: 500;
        font-size: 1.55vw;
        line-height: 20px; }

    &__body-wrapper {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;

        height: 90%;
        width: 100%;

        padding-top: 6%;
        // border: 1px solid red


        &__tabs-wrapper {

            display: flex;
            justify-content: center;
            // border: 1px solid red

            height: 5.1%;
            width: 88%;

            box-sizing: border-box;

            &__hidden {
                width: 0px;
                overflow: hidden; }

            &:nth-last-child(3) {
                // border: 1px solid red
                border-top: 2px solid #D1D2D3;
                padding-top: 6%;
                margin-top: 5%;
                height: 8.5%; }

            &:nth-last-child(2) {
                border-top: 2px solid #D1D2D3;
                padding-top: 6%;
                height: 8.5%;
                margin-top: 125%; }

            &__tab {
                // border: 1px solid black
                display: flex;

                align-items: center;

                width: 14.9vw;
                height: 100%;

                padding-left: 9%;

                box-sizing: border-box;
                gap: 1.5%;

                color: #161616;
                transition: all 0.3s ease-in-out;

                &:hover {
                    padding-left: 15%; }


                &__icon {
                    display: flex;
                    // border: 1px solid red
                    width: 1.5vmax;
                    height: 1.5vmax; }

                &__name {
                    font-family: Geometria-light;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.9vw;
                    line-height: 20px;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off; } }

            .chose {
                padding-left: 9%;
                background: #F9FDFF; } }


        &__log-out-wrapper {
            position: absolute;
            bottom: 0px;

            display: flex;
            align-items: center;

            width: 94%;
            border-top: 2px solid #D1D2D3;
            height: 14.7%;


            &__icon {
                width: 3.5vmax;
                height: 3.5vmax;
                border-radius: 50%; }

            &__name {
                // border: 1px solid red

                width: 60%;

                overflow: hidden;
                text-overflow: ellipsis;

                margin-left: 0.4vw;

                font-family: Geometria;
                font-style: normal;
                font-weight: bold;
                font-size: 1vw;
                line-height: 20px;
                text-align: center;

                letter-spacing: 0.01em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                color: #444444; }

            &__button {
                // border: 1px solid red
                height: 3vmin;
                width: 3vmin;
                margin-left: 0.5vw;
                cursor: pointer; } } } }

