.profile-payments-wrapper {
    position: relative;
    margin: 0 1.8vw;
    width: 95%;
    height: 100%;

    &__receipt-wrapper {
        margin-top: 4vh;
        width: 100%;
        min-height: 45%;

        &__header {
            display: flex;
            align-items: center;

            // border: 1px solid red
            width: 30vw;
            height: 5vh;

            font-family: Geometria-Bold;
            font-style: normal;
            font-weight: bold;
            font-size: 1.85vw;
            line-height: 36px;

            letter-spacing: -0.025em;
            font-feature-settings: 'tnum' on, 'lnum' on;

            color: #000000; }

        &__title {
            margin-top: 0.5vh;
            margin-left: 1.72vw;

            display: flex;
            align-items: center;

            height: 4.4vh;

            font-family: Geometria;
            font-style: normal;
            font-weight: normal;
            font-size: 1.05vw;
            line-height: 20px;

            letter-spacing: 0.01em;
            font-feature-settings: 'tnum' on, 'lnum' on;

            color: rgba(0, 0, 0, 0.65); }

        &__receipt-data-header {
            margin-top: 1.7vh;
            display: flex;
            align-items: center;
            gap: 0.5vw;
            // padding: 12px 8px
            height: 5.5vh;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);


            &__title {
                width: 13vw;

                padding-left: 0.5vw;
                box-sizing: border-box;
                // border: 1px solid red

                font-family: Geometria;
                font-style: normal;
                font-weight: normal;
                font-size: 0.78vw;
                line-height: 16px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                color: rgba(0, 0, 0, 0.65);

                &:last-child {
                    width: 21.5vw; } } }


        &__receipt-data-wrapper {

            &__receipt-data {
                display: flex;
                align-items: center;
                gap: 0.5vw;
                height: 5.5vh;
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);


                &__title {
                    display: flex;
                    // align-items: flex-end

                    padding-left: 0.5vw;
                    // border: 1px solid red
                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #000000;

                    width: 13vw;

                    &:last-child {
                        // display: flex
                        // align-items: center
                        // border: 1px solid red
                        width: 21.5vw; }

                    input {
                        outline: 0;
                        border: 1px solid rgba(0, 0, 0, 0.3);

                        width: 11.5vw;

                        font-family: Geometria;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 0.91vw;
                        line-height: 20px;

                        letter-spacing: 0.01em;
                        font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                        color: rgba(0, 0, 0, 0.9);
                        border-radius: 2px;

                        &::placeholder {
                            text-align: center;
                            color: rgba(0, 0, 0, 0.3); } }

                    &__button {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        width: 4.15vw;
                        height: 3.8vh;

                        margin-left: 0.9vw;

                        font-family: Geometria-Light;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 1.04vw;
                        line-height: 20px;
                        text-align: center;
                        letter-spacing: 0.01em;
                        font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                        color: #FFFFFF;

                        background: #0074AB;
                        border-radius: 3px;

                        cursor: pointer; } } } } }



    &__table-wrapper {
        // border: 1px solid red
        position: relative;

        display: flex;
        flex-direction: column;

        width: 100%;
        min-height: 95%;


        &__header-wrapper {
            display: flex;
            gap: 1.8vw;
            // border: 1px solid red

            &__headers {
                // border: 1px solid black
                display: flex;
                align-items: center;
                height: 6.55vh;

                &__title {
                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.78vw;
                    line-height: 16px;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: rgba(0, 0, 0, 0.65); }

                &:nth-last-child(6) {
                    $widthFromHeader: 14.4vw !global;
                    padding-left: 1.9vw;
                    width: $widthFromHeader; }

                &:nth-last-child(5) {
                    $widthToHeader: 13.7vw !global;
                    width: $widthToHeader; }

                &:nth-last-child(4) {
                    padding-left: 1vw;
                    box-sizing: border-box;
                    $widthPaymentPeriodHeader: 11.8vw !global;
                    width: $widthPaymentPeriodHeader; }

                &:nth-last-child(3) {
                    // border: 1px solid red
                    $widthAmountHeader: 8.4vw !global;

                    padding-left: 1.2vw;
                    width: $widthAmountHeader;

                    box-sizing: border-box; }

                &:nth-last-child(2) {
                    // border: 1px solid red
                    $widthStatusHeader: 16.6vw !global;
                    padding-left: 1.3vw;
                    width: $widthStatusHeader;
                    box-sizing: border-box; }

                &:nth-last-child(1) {
                    $widthReceiptHeader: fit-content !global;
                    width: $widthReceiptHeader;
 } } }                    // border: 1px solid red



        &__table-data {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding-bottom: 10%;


            &__data-line {
                // border: 1px solid red
                padding: 0 1.95vw;
                border-top: 1px solid rgba(0, 0, 0, 0.15);

                display: flex;
                align-items: center;

                width: 95%;
                height: 6.6vh;
                gap: 2vw;

                &__title {
                    font-family: Geometria;
                    font-style: normal;
                    font-weight: 100;
                    font-size: 0.91vw;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    padding-left: 0.55vw;

                    color: #000000; }

                &__from {
                    display: flex;
                    align-items: center;

                    width: $widthFromHeader;
                    // border: 1px solid red
                    height: 4.5vh;

                    text-overflow: ellipsis; }


                &__to {
                    display: flex;
                    align-items: center;

                    width: $widthToHeader;
                    // border: 1px solid red
                    height: 4.5vh;

                    text-overflow: ellipsis;

                    &__button {
                        // border: 1px solid red
                        width: 3.3vmin;
                        height: 3.3vmin; } }

                &__payment {
                    display: flex;
                    align-items: center;
                    // border: 1px solid red

                    width: $widthPaymentPeriodHeader;
                    height: 4.5vh;

                    text-overflow: ellipsis; }

                &__amount {
                    display: flex;
                    align-items: center;
                    // border: 1px solid red
                    width: $widthAmountHeader;
                    height: 4.5vh;

                    text-overflow: ellipsis; }

                &__status {
                    display: flex;
                    align-items: center;
                    width: $widthStatusHeader;
                    height: 4.5vh;

                    text-overflow: ellipsis;

                    &__background {
                        // border: 1px solid red
                        display: flex;
                        align-items: center;
                        padding: 0 0.2vw;

                        width: 100%;
                        height: 3.5vh;

                        border-radius: 3px;
                        box-sizing: border-box; } }

                &__receipt {
                    display: flex;
                    align-items: center;
                    // border: 1px solid red
                    width: $widthReceiptHeader;

                    height: 4vh;

                    cursor: pointer; } } } } }







