.pagination-wrapper {
    display: flex;
    align-items: center;
    // border: 1px solid red
    height: 100%;

    &__left-button {
        display: flex;
        align-items: flex-end;

        width: 4.2vmin;
        height: 4.2vmin; }

    &__right-button {
        display: flex;
        align-items: flex-end;
        width: 4.2vmin;
        height: 4.2vmin;
 }        // border: 1px solid red

    &__quantity {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 4.1vh;
        // border: 1px solid red
        margin-left: 1.3vw;
        font-size: 1.05vw;
        user-select: none; } }
