.app-wrapper {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100vh;

    .app-navbar {
        position: fixed;
        width: 15%;
        height: 100%;
 }        // border: 1px solid red

    .app-right-side {
        // border: 1px solid red
        margin-left: 15%;
        width: 85%;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box; } }
