.confirmation-wrapper {
    position: relative;
    margin-top: -2%;

    width: 100%;
    min-height: 86vh;

    padding: 0 2.5%;
    padding-bottom: 7%;

    box-sizing: border-box;

    &__table-wrapper {
        margin-bottom: 1%;
        width: 100%;
        padding: 0 3%;

        box-sizing: border-box;

        border: 1px solid rgba(0, 0, 0, 0.16);
        box-sizing: border-box;
        border-radius: 10px;

        &__header {
            display: flex;
            justify-content: space-between;
            // border: 1px solid red
            width: 100%;

            &__name {
                // border: 1px solid red
                display: flex;

                height: 5vh;

                margin-top: 3%;
                margin-bottom: 2.75%;

                gap: 2.5%;

                &__avatar {
                    // border: 1px solid blue
                    border-radius: 50%;
                    width: 2.3vmax;
                    height: 2.3vmax; }

                &__title {
                    width: 20vw;
                    height: 5vh;
                    font-family: Geometria-Bold;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 1.62vw;
                    line-height: 20px;

                    display: flex;
                    align-items: center;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #000000; } }


            &__confirm-button {
                margin-top: 1.75%;

                width: 16.3vw;
                height: 5.5vh;

                background: #0074AB;
                border-radius: 3px;
                cursor: pointer;

                &__title {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    width: 100%;
                    height: 5.4vh;

                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.91vw;
                    line-height: 20px;

                    text-align: center;
                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #FFFFFF; } } }

        &__data-wrapper {
            // border: 1px solid green
            // height: 200px

            &__header-wrapper {
                display: flex;
                width: 100%;
                height: 4.7vh;
                // border: 1px solid red

                &__header {
                    display: flex;
                    $UpTableWidth: 15.8vw !global;
                    width: $UpTableWidth;
                    align-items: center;
                    // border: 1px solid black

                    &__title {
                        font-family: Geometria;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.04vw;
                        line-height: 16px;

                        display: flex;
                        align-items: center;

                        letter-spacing: 0.08em;
                        text-transform: uppercase;
                        font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                        /* Text & Icon/On Light - Low */

                        color: rgba(0, 0, 0, 0.65); } } }

            &__data {
                display: flex;
                // border: 1px solid red
                height: 5vh;

                &__block {
                    // border: 1px solid red
                    display: flex;
                    align-items: center;

                    width: $UpTableWidth;
                    height: 100%;

                    font-family: Geometria-Bold;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.04vw;
                    line-height: 20px;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #000000; } }

            &__about-project {
                margin-top: 1.5%;
                // border: 1px solid green
                width: 100%;
                box-sizing: border-box;

                &__header-wrapper {
                    display: flex;
                    // border: 1px solid black
                    height: 5vh;


                    &__header {
                        // border: 1px solid black
                        width: 9vw;
                        padding-left: 2%;
                        box-sizing: border-box;

                        &:nth-last-child(6) {
                            $ProjectWidth: 14vw !global;
                            width: $ProjectWidth; }

                        &:nth-last-child(5) {
                            $RoleWidth: 13vw !global;
                            width: $RoleWidth; }

                        &:nth-last-child(5) {
                            $RoleWidth: 13vw !global;
                            width: $RoleWidth; }

                        &__title {
                            height: 100%;

                            font-family: Geometria;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 0.81vw;
                            line-height: 16px;

                            display: flex;
                            align-items: center;
                            letter-spacing: 0.08em;
                            text-transform: uppercase;
                            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                            color: #585859; } } }

                &__data {
                    // border: 1px solid black

                    &__string {
                        // border: 1px solid black
                        display: flex;
                        height: 9.6vh;
                        border-top: 1px solid #E5E5E5;
                        padding: 2% 0;
                        box-sizing: border-box;

                        &__block {
                            // border: 1px solid black
                            width: 9vw;

                            font-family: Geometria;
                            font-style: normal;
                            font-weight: normal;
                            font-size: 1.05vw;
                            line-height: 35px;

                            display: flex;
                            align-items: center;

                            letter-spacing: 0.01em;
                            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                            color: #000000;
                            box-sizing: border-box;
                            padding-left: 2%;

                            &:nth-last-child(6) {
                                width: $ProjectWidth;
                                padding-left: 0%;
 }                                // border: 1px solid red

                            &:nth-last-child(5) {
                                width: $RoleWidth; } } } } } } } }





