.projects-container-wrapper {
    position: relative;
    display: flex;
    // border: 1px solid black
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;

    &__navbar-wrapper {
        border-right: 2px solid #D1D2D3;

        position: fixed;
        display: block;

        padding-top: 8vh;

        height: 100%;

        width: 16vw;
        margin: 0 auto;
        margin-left: calc(100vw - 100%);
        margin-right: 0;

        // border: 1px solid red
        background: rgba(235, 249, 255, 0.32);

        padding-bottom: 10%;
        box-sizing: border-box;

        overflow-y: scroll;
        scrollbar-width: thin;
        scrollbar-color: rgba(235, 249, 255, 0) rgba(235, 249, 255, 0);

        &::-webkit-scrollbar {
            width: 7px; }

        &:hover {
            scrollbar-width: thin;
            scrollbar-color: #c9c9c9f8 rgba(235, 249, 255, 0.32);
            &::-webkit-scrollbar-thumb {
                background: #c9c9c9f8;
                border-radius: 10px; } }



        &__appellation-wrapper {
            // border: 1px solid black

            margin-left: 8%;

            max-height: 5.6vh;

            box-sizing: border-box;

            overflow: hidden;

            transition: all 0.5s ease-in-out;

            cursor: pointer;


            &__title {
                // border: 1px solid black
                display: flex;
                position: relative;
                padding-left: 11%;
                align-items: center;
                height: 5vh;

                font-family: Geometria;
                font-style: normal;
                font-weight: normal;
                font-size: 1.04vw;
                line-height: 20px;

                letter-spacing: 0.01em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                color: rgba(0, 0, 0, 0.65);

                transition: all 0.2s ease-in-out;

                &__arrow {
                    position: absolute;
                    right: 1vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    // border: 1px solid black
                    height: 1vh;
                    width: 0.5vw; }

                .down {
                    transform: rotate(180deg); }

                &:hover {
                    padding-left: 15%; } }


            .opening-title {
                color: rgba(0, 0, 0, 0.9);
                padding-left: 11%;
                border-left: 4px solid #0074AB; }


            &__appellation-data {
                width: 86%;
                height: fit-content;

                margin-top: 1.2vh;
                margin-left: 14%;

                // border: 1px solid red

                box-sizing: border-box;



                &__title-wrapper {
                    // border: 1px solid red
                    border-left: 1px solid rgba(0, 0, 0, 0.15);
                    height: 8.3vh;
                    // padding: 5%
                    padding: 2% 5%;
                    width: 92%;
                    cursor: pointer;
                    // box-sizing: border-box

                    color: rgba(0, 0, 0, 0.65);

                    transition: all 0.2s ease-in-out;

                    &:hover {
                        padding-left: 7%;
 }                        // background: #D9F1FF

                    &__title {
                        // border: 1px solid black
                        width: 98%;
                        height: 100%;
                        display: flex;
                        align-items: center;

                        font-family: Geometria;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 0.91vw;
                        line-height: 23px;

                        letter-spacing: 0.01em;
                        font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off; } }


                .chose {
                    padding-left: 7%;
                    border-left: 1px solid #0074AB;

                    color: rgba(0, 0, 0, 0.9); } } }

        .opening {
            min-height: 0;
            max-height: 500%;
            transition: all 1.5s ease-in-out; } }

    &__data-wrapper {
        position: relative;
        left: 18.75%;
        // border: 1px solid red
        width: 82%;
        height: 100%;
        box-sizing: border-box;

        &__header {
            // border: 1px solid red
            display: flex;
            gap: 3.5%;

            margin-left: 3.8%;
            margin-top: 1.5%;

            width: 90%;
            height: 8.8vh;

            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            &__title {
                // border: 1px solid red
                display: flex;
                align-items: center;
                height: 8.6vh;
                padding: 0 0.5%; }

            .chosenHeader {
                height: 8.8vh;
                padding-top: 0.4%;
                border-bottom: 4px solid #0074AB;
                box-sizing: border-box; } }

        &__data {
            // border: 1px solid green
            margin-top: 7%;

            display: block;
            height: 30vh;
            box-sizing: border-box; } } }
