.profile-container-wrapper {
    position: relative;
    // border: 1px solid black
    width: 100%;
    height: 95%;
    box-sizing: border-box;

    &__title {
        // border: 1px solid red
        position: relative;
        margin-left: 1.8vw;
        margin-top: 3.9vh;

        width: 10vw;
        height: 6vh;

        font-family: Geometria-Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 2.6vw;
        line-height: 52px;

        letter-spacing: -0.025em;
        font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

        color: #000000; }

    &__pages {
        display: flex;
        align-items: center;

        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        margin-left: 1.8vw;

        width: fit-content;
        height: 8.9vh;
        gap: 1.3vw;

        &__name {
            // border: 1px solid red
            display: flex;
            padding: 0 0.65vw;
            width: fit-content;
            height: 8.9vh;
            font-family: Geometria-Light;
            font-style: normal;
            font-weight: normal;
            font-size: 0.91vw;

            display: flex;
            align-items: center;
            letter-spacing: 0.01em;
            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

            /* Text & Icon/On Light - Low */

            color: rgba(0, 0, 0, 0.65); }

        .chose {
            // border: 1px solid red
            height: 8vh;
            padding-top: 0.5vh;
            border-bottom: 4px solid #4169E1;
            color: rgba(0, 0, 0, 0.9); } }

    &__data {
        // border: 2px solid green
        position: relative;
        width: 100%;
        height: 83%;
        box-sizing: border-box; } }
