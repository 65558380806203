.ConfirmationOfPayment-wrapper {
    padding: 0 1.8vw;
    padding-top: 5.3vh;
    // border: 1px solid red
    min-height: 50vh;
    padding-bottom: 20vh;
    box-sizing: border-box;

    &__data-wrapper {
        margin-bottom: 2.8vh;

        border: 1px solid rgba(0, 0, 0, 0.16);
        border-radius: 10px;

        &__header-line {
            display: flex;
            justify-content: space-between;

            &__title {
                margin-left: 2.35vw;
                margin-top: 5.5vh;
                font-family: Geometria-Bold;
                font-style: normal;
                font-weight: bold;
                font-size: 1.62vw;
                line-height: 20px;

                display: flex;
                align-items: center;
                letter-spacing: 0.01em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                color: #000000; }

            &__button {
                display: flex;
                justify-content: center;
                margin-right: 2.4vw;
                margin-top: 2.5vh;
                border: 1px solid blue;
                padding: 0.9vh 0.5vw;

                width: 16.1vw;
                height: 4.8vh;

                background: #0074AB;
                border-radius: 3px;

                cursor: pointer;

                &__title {
                    height: 100%;
                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.91vw;
                    line-height: 20px;

                    display: flex;
                    align-items: center;
                    text-align: center;
                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #FFFFFF; } } }

        &__AboveTableInfo {
            margin-top: 5.15vh;
            // border: 1px solid red

            &__header {
                display: flex;

                padding-left: 2.35vw;
                padding-right: 0.7vw;
                height: 5.5vh;

                &__title {
                    display: flex;
                    align-items: center;

                    width: 25.6vw;
                    // border: 1px solid red
                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.03vw;
                    line-height: 16px;

                    display: flex;
                    align-items: center;
                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: rgba(0, 0, 0, 0.65); } }

            &__data {
                display: flex;
                padding-left: 2.35vw;
                box-sizing: border-box;

                // border: 1px solid red

                &__title {
                    width: 25.6vw;
                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 1.3vw;
                    line-height: 20px;

                    display: flex;
                    align-items: center;
                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #000000; } } }

        &__table-wrapper {
            // border: 1px solid red
            margin-top: 4.2vh;
            padding-left: 1.8vw;
            box-sizing: border-box;

            &__header {
                display: flex;
                align-items: center;
                height: 5.5vh;
                border-bottom: 1px solid rgba(0, 0, 0, 0.16);

                &__title {
                    width: 13.5vw;
                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.78vw;
                    line-height: 16px;

                    letter-spacing: 0.08em;
                    text-transform: uppercase;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: rgba(0, 0, 0, 0.65);

                    &:nth-last-child(5) {
                        $widthProject: 21vw !global;
                        width: $widthProject;
                        padding-left: 0.5vw;
                        box-sizing: border-box; }

                    &:nth-last-child(4) {
                        $widthRole: 20vw !global;
                        width: $widthRole; } } }

            &__table {
                // border: 1px solid red
                margin-bottom: 5vh;

                &__data {
                    display: flex;
                    align-items: center;
                    // border: 1px solid red
                    height: 7.8vh;

                    &__title {
                        width: 13.5vw;
                        font-family: Geometria;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 1.05vw;
                        line-height: 20px;

                        letter-spacing: 0.01em;
                        font-feature-settings: 'tnum' on, 'lnum' on;

                        color: #000000;

                        &:nth-last-of-type(5) {
                            width: $widthProject;
                            padding-left: 0.5vw;
                            box-sizing: border-box; }

                        &:nth-last-child(4) {
                            width: $widthRole; } } } } } } }



