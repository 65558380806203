.finances-container-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 99vh;
    // border: 1px solid black
    box-sizing: border-box;

    &__navbar-wrapper {
        border-right: 2px solid #D1D2D3;
        position: fixed;
        display: flex;
        width: 15.2vw;
        height: 100%;
        // border: 1px solid red
        background: rgba(235, 249, 255, 0.32);

        &__team-wrapper {
            // border: 1px solid red
            width: 100%;
            margin-left: 8.5%;
            margin-top: 23%;
            box-sizing: border-box;

            &__title {
                // border: 1px solid red
                display: flex;
                align-items: center;

                border-left: 4px solid #0074AB;

                position: relative;

                width: 100%;
                height: 5.5vh;
                padding-left: 9%;

                box-sizing: border-box;

                font-family: Geometria-Bold;
                font-style: normal;
                font-weight: bold;
                font-size: 1.24vw;
                line-height: 20px;

                letter-spacing: 0.01em;
                font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                color: rgba(0, 0, 0, 0.9); }


            &__tabs {
                width: 80%;
                margin-left: 15%;
                margin-top: 4.5%;

                &__title {
                    // border: 1px solid black
                    display: flex;
                    align-items: center;
                    padding-left: 11.5%;
                    border-left: 1px solid rgba(0, 0, 0, 0.15);
                    width: 93%;
                    height: 4.45vh;
                    font-family: Geometria;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 0.91vw;
                    line-height: 20px;

                    letter-spacing: 0.01em;
                    font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

                    color: #575959;

                    cursor: pointer; }

                &__hidden {
                    width: 0;
                    height: 0;
                    overflow: hidden; }

                .chose {
                    border-left: 1px solid #0074AB;
                    color: rgba(0, 0, 0, 0.9); } } } }

    &__data-wrapper {
        position: relative;
        left: 18%;
        // border: 1px solid blue
        width: 82%;
        box-sizing: border-box;

        &__header {
            display: flex;
            gap: 2.7%;

            margin-top: 3.7%;
            margin-left: 4.3%;

            // border: 1px solid red

            width: 80%;
            height: 3vh;

            font-family: Geometria-Bold;
            font-style: normal;
            font-size: 1.11vw;
            font-weight: bold;
            line-height: 20px;

            letter-spacing: 0.01em;
            font-feature-settings: 'tnum' on, 'lnum' on, 'liga' off;

            color: #595959; }

        &__table-wrapper {
            // border: 1px solid green
            margin-top: 5.1%;

 } } }            // box-sizing: border-box

